import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [2];

export const dictionary = {
		"/(loggedOut)": [37,[10]],
		"/(loggedIn)/account": [13,[2]],
		"/(loggedOut)/agb": [40,[10]],
		"/(loggedIn)/(onboarding)/create-organization": [12,[2,3]],
		"/(loggedOut)/datenschutz": [41,[10]],
		"/(loggedOut)/impressum": [42,[10]],
		"/(loggedIn)/org": [~14,[2]],
		"/(loggedIn)/org/[orgId]/(onboarded)": [16,[2,4]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars": [17,[2,4]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars/[id]": [18,[2,4,5]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars/[id]/automations": [19,[2,4,5]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars/[id]/schedule": [20,[2,4,5]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars/[id]/settings": [21,[2,4,5]],
		"/(loggedIn)/org/[orgId]/(onboarded)/calendars/[id]/setup": [22,[2,4,5]],
		"/(loggedIn)/org/[orgId]/(deactivated)/deactivated": [15,[2]],
		"/(loggedIn)/org/[orgId]/(onboarded)/forms": [23,[2,4]],
		"/(loggedIn)/org/[orgId]/(onboarded)/forms/[id]": [24,[2,4,6]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables": [25,[2,4]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]": [26,[2,4,7]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]/automations-new": [29,[2,4,7]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]/automations": [~28,[2,4,7]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]/settings": [30,[2,4,7]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]/setup": [31,[2,4,7]],
		"/(loggedIn)/org/[orgId]/(onboarded)/tables/[id]/[entryId]": [~27,[2,4,8]],
		"/(loggedIn)/org/[orgId]/(onboarded)/team": [32,[2,4,9]],
		"/(loggedIn)/org/[orgId]/(onboarded)/team/billing": [~33,[2,4,9]],
		"/(loggedIn)/org/[orgId]/(onboarded)/team/integrations-new": [35,[2,4,9]],
		"/(loggedIn)/org/[orgId]/(onboarded)/team/integrations": [~34,[2,4,9]],
		"/(loggedIn)/org/[orgId]/(onboarded)/webhooks": [~36,[2,4]],
		"/(loggedOut)/(auth)/sign-in": [38,[11]],
		"/(loggedOut)/(auth)/sign-up": [39,[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';